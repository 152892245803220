import config from 'shared/config'
import * as t from 'io-ts'
import { MyIDUser } from '@genome-web-forms/common/auth'
import { request } from '@genome-web-forms/common/api'
import { authGWFWOUserData } from 'api/auth'
import { queryClient } from 'shared/queryClient'
import { CastMember } from 'codecs/CastMember'
import { buildMergedPortrayalsData } from 'shared/components/Characters/utils'

export type ResourceType = 'feature' | 'season' | 'episode' | 'series'
export interface ResourceRequest {
    resourceType: ResourceType
    resourceId: string
    user: MyIDUser
}

export type fetchResourceConfig<C extends t.Mixed> = ResourceRequest & {
    codec: C
}

export function fetchResource<C extends t.Mixed>({
    codec,
    resourceId,
    user,
    resourceType,
}: fetchResourceConfig<C>): Promise<t.TypeOf<C>> {
    return queryClient.fetchQuery(['resource', resourceType, resourceId, user.email], () =>
        request(
            codec,
            authGWFWOUserData(user, {
                url: `${config.urlGWF}/creativework/${resourceType}/${encodeURIComponent(
                    resourceId,
                )}`,
            }),
        ).then(data => {
            if (!!data.characters) {
                const characters = data.characters as CastMember[]
                data.characters = buildMergedPortrayalsData(characters)
            }

            return data
        }),
    )
}
